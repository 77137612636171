@import '../../../../scss/theme-bootstrap';

.adv-cal {
  &__offer-banner {
    font-family: $ano-bold_regular-font;
    text-transform: uppercase;
    position: relative;
    z-index: 20;
    .content-block__line {
      padding-bottom: 8px;
      font-size: 14px;
      line-height: get-line-height(14px, 14px);
    }
    .hero-block {
      &__wrapper {
        line-height: 1;
        .content-over-media__text,
        .hero-block__line--eyebrow {
          &.breadcrumb {
            font-family: $ano-bold_regular-font;
            letter-spacing: 0;
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: get-line-height(14px, 14px);
          }
        }
      }
      &__content-over-media {
        padding-top: 50px;
        @include breakpoint($bp--large-up) {
          padding-top: 60px;
        }
      }
    }
    h3 {
      font-family: $ano_regular-font;
      font-size: 28px;
      line-height: get-line-height(28px, 33.6px);
      padding-bottom: 0;
      margin: 0;
      @include breakpoint($bp--large-up) {
        font-size: 36px;
        line-height: get-line-height(36px, 36px);
      }
    }
    p {
      font-family: $ano_regular-font;
      font-size: 16px;
      line-height: get-line-height(16px, 22.4px);
      padding-bottom: 8px;
      margin: 0;
      @include breakpoint($bp--large-up) {
        font-size: 24px;
        line-height: get-line-height(24px, 28.8px);
      }
    }
  }
  &__tiles-block {
    font-family: $ano-bold_regular-font;
    z-index: 19;
    margin-top: -16%;
    @include breakpoint($bp--large-up) {
      margin-top: -5%;
    }
    .tile-heading {
      text-align: center;
      margin: 0;
      padding: 0;
    }
    .tile-number {
      color: $color--white;
      font-family: $ano_regular-font;
      font-size: 80px;
      line-height: get-line-height(80px, 100px);
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      font-weight: normal;
      width: 50%;
      display: block;
      #{$ldirection}: 25%;
      @include breakpoint($bp--large-up) {
        font-size: 120px;
        line-height: get-line-height(120px, 174px);
      }
    }
  }
  &__holiday-banner {
    font-family: $ano_regular-font;
    .content-block__line {
      padding-bottom: 0;
    }
    h3 {
      margin: 0;
      text-transform: uppercase;
      font-family: $ano_regular-font;
      font-size: 28px;
      line-height: get-line-height(28px, 34.72px);
      padding: 30px 25px 8px;
      @include breakpoint($bp--large-up) {
        font-size: 36px;
        line-height: get-line-height(36px, 36px);
        padding: 30px 0 8px;
      }
    }
    p {
      font-size: 16px;
      line-height: get-line-height(16px, 22.4px);
      padding: 0 25px 8px;
      margin: 0;
      @include breakpoint($bp--large-up) {
        padding: 0 0 8px;
      }
    }
    .button {
      padding: 0 30px;
      border-radius: 25px;
      line-height: 3;
      margin-top: 8px;
      font-family: $ano-bold_regular-font;
    }
  }
}

.offer-details {
  font-family: $ano_regular-font;
  background: url('/media/export/cms/adv_cal/adv_cal_popup_bg_mob.png') no-repeat 100%;
  @include breakpoint($bp--large-up) {
    background-image: url('/media/export/cms/adv_cal/adv_cal_popup_bg_pc.png');
  }
  &__img {
    padding: 10% 10% 0;
    @include breakpoint($bp--large-up) {
      width: 36%;
      height: 100%;
      padding: 3%;
    }
    img {
      width: 100%;
    }
  }
  &__content {
    width: 100%;
    text-align: center;
    margin-top: 6%;
    color: $color--white;
    text-transform: uppercase;
    padding: 0 10%;
    @include breakpoint($bp--large-up) {
      padding: 0 40px;
      margin-top: 30px;
      width: 64%;
    }
    .offer-eyebrow-text {
      font-size: 13px;
      line-height: get-line-height(13px, 16.9px);
      margin-bottom: 0;
    }
    .offer-title {
      font-family: $ano-bold_regular-font;
      font-size: 28px;
      line-height: get-line-height(28px, 30px);
      padding-bottom: 8px;
      margin: 0;
      @include breakpoint($bp--large-up) {
        font-size: 30px;
        line-height: get-line-height(30px, 30px);
      }
    }
    .offer-subtitle {
      font-family: $ano-bold_regular-font;
      font-size: 18px;
      line-height: get-line-height(18px, 22px);
      padding-bottom: 8px;
      margin: 0;
      @include breakpoint($bp--large-up) {
        font-size: 24px;
        line-height: get-line-height(24px, 24px);
      }
    }
    .offer-description {
      font-size: 16px;
      line-height: get-line-height(16px, 22.4px);
      margin-bottom: 10px;
      text-transform: none;
      @include breakpoint($bp--large-up) {
        font-size: 16px;
        line-height: get-line-height(16px, 16px);
      }
    }
  }
  &__controls {
    text-align: center;
    width: 100%;
    @include breakpoint($bp--large-up) {
      width: 64%;
    }
    .btn-wrapper {
      display: inline-block;
    }
    .button {
      padding: 0 30px;
      border-radius: 25px;
      line-height: 3;
      color: $color--white;
      margin: 0;
      font-family: $ano-bold_regular-font;
    }
  }
}

.adv_cal_cap {
  .adv-cal-cap {
    &-title {
      font-family: $ano_regular-font;
      text-align: center;
      color: $color--white;
      text-transform: uppercase;
      font-size: 36px;
      line-height: get-line-height(36px, 36px);
      padding: 0 25px 8px;
      @include breakpoint($bp--large-up) {
        font-size: 60px;
        line-height: get-line-height(60px, 60px);
        padding-bottom: 16px;
      }
      p {
        font-family: $ano_regular-font;
        margin-bottom: 15px;
      }
    }
    &-subtitle,
    &-anchor-active {
      text-align: center;
      color: $color--white;
      font-family: $ano_regular-font;
      font-size: 16px;
      line-height: get-line-height(16px, 22.4px);
      padding: 0 25px 8px;
      @include breakpoint($bp--large-up) {
        font-size: 24px;
        line-height: get-line-height(24px, 29.76px);
        padding: 0 100px 50px;
      }
      a {
        color: $color--white;
        font-family: $ano-bold_regular-font;
        font-size: 14px;
        line-height: get-line-height(14px, 19.6px);
      }
    }
    &-container {
      .adv-cal-cap-wrapper {
        padding: 20% 0;
        @include breakpoint($bp--large-up) {
          padding: 10% 0;
        }
        .cards {
          .card {
            &.tile {
              &.active {
                border: none;
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
      .adv-cal-cap-popup {
        .offer-details__img {
          .adv-cal__tiles-block & {
            float: #{$rdirection};
          }
        }
      }
    }
  }
}
